import {extendTheme} from "@chakra-ui/react"

const ChakraTheme = extendTheme({
    styles: {
        global: {
            body: {
                bg: "#f8f8f8"
            }
        }
    },
    fonts: {
        heading: "Lato",
        body: "Lato",
    },
    colors: {
        brand: '#2d78f7'
    },
})

export default ChakraTheme;
