import * as React from "react";
import {ChakraProvider} from "@chakra-ui/react"
import {
    MemoryRouter,
    Route,
    Routes
} from "react-router-dom";
import ReactGA from 'react-ga';
import {IntlProvider} from "react-intl";
import German from './lang/de.json';

import PrimaTemplate from "./templates/Prima";
import ChakraTheme from "./templates/Chakra";

const Home = React.lazy(() => import("./pages/home/Home"));
const Step1 = React.lazy(() => import("./pages/step1/Step1"));
const Step2 = React.lazy(() => import("./pages/step2/Step2"));
const Step3 = React.lazy(() => import("./pages/step3/Step3"));
const Step4 = React.lazy(() => import("./pages/step4/Step4"));
const Step5 = React.lazy(() => import("./pages/step5/Step5"));
const Loader = React.lazy(() => import("./pages/loader/Loader"));
const Complete = React.lazy(() => import("./pages/complete/Complete"));

function App() {
    ReactGA.initialize('UA-183263461-26');
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
        <MemoryRouter>
            <ChakraProvider theme={ChakraTheme}>
                <IntlProvider locale={'de'} messages={German} defaultLocale="de">
                    <PrimaTemplate>
                        <Routes>
                            <Route path='/' element={
                                <React.Suspense fallback={<></>}>
                                    <Home/>
                                </React.Suspense>
                            }/>
                            <Route path='/step1' element={
                                <React.Suspense fallback={<></>}>
                                    <Step1/>
                                </React.Suspense>
                            }/>
                            <Route path='/step2' element={
                                <React.Suspense fallback={<></>}>
                                    <Step2/>
                                </React.Suspense>
                            }/>
                            <Route path='/step3' element={
                                <React.Suspense fallback={<></>}>
                                    <Step3/>
                                </React.Suspense>
                            }/>
                            <Route path='/step4' element={
                                <React.Suspense fallback={<></>}>
                                    <Step4/>
                                </React.Suspense>
                            }/>
                            <Route path='/step5' element={
                                <React.Suspense fallback={<></>}>
                                    <Step5/>
                                </React.Suspense>
                            }/>
                            <Route path='/loader' element={
                                <React.Suspense fallback={<></>}>
                                    <Loader/>
                                </React.Suspense>
                            }/>
                            <Route path='/complete' element={
                                <React.Suspense fallback={<></>}>
                                    <Complete/>
                                </React.Suspense>
                            }/>
                        </Routes>
                    </PrimaTemplate>
                </IntlProvider>
            </ChakraProvider>
        </MemoryRouter>
    );
}

export default App;
